/* global Cookies, lightbox */
/* eslint-env jquery, cookie */

'use strict';

jQuery( function( $ ) {

	$( document ).on( 'click', '#showSearchForm', function() {
		const wrap = $( '#searchForm' );
		const button = $( this );

		if ( ! wrap.hasClass( 'visible' ) ) {
			wrap.slideDown();
			wrap.addClass( 'visible' );
			button.addClass( 'open' );
		}
	} );

	$( document ).on( 'click', '#hideSearchForm', function() {
		const wrap = $( '#searchForm' );
		const button = $( '#showSearchForm' );

		if ( wrap.hasClass( 'visible' ) ) {
			wrap.slideUp();
			wrap.removeClass( 'visible' );
			button.removeClass( 'open' );
		}
	} );

	if ( $( '.reveal' ).length ) {
        const reveal = new WOW( {
            boxClass:     'reveal',
            animateClass: 'animated',
            mobile:        true,
            live:          true,
        } );
        reveal.init();
    }

	$( window ).scroll( function() {
		// Handle sticky menu
		if ( $( this ).scrollTop() > 100 ) {
			$( '.site-header' ).addClass( 'sticky' );
		} else {
			$( '.site-header' ).removeClass( 'sticky' );
		}
	} );

	$( '#slider' ).slick( {
		arrows: true,
		infinite: true,
		speed: 1200,
		slidesToShow: 1,
		autoplay: false,
		autoplaySpeed: 6000,
		pauseOnHover: false,
		centerMode: true,
		variableWidth: true,
		responsive: [
			{
			  breakpoint: 576,
			  settings: {
				arrows: false,
				centerMode: false,
				variableWidth: false,
			  }
			},
		  ]
	} );

	$( '#locationSlider' ).slick( {
		arrows: false,
		dots: true,
		infinite: true,
		speed: 1200,
		slidesToShow: 1,
		autoplay: true,
		autoplaySpeed: 6000,
		pauseOnHover: false,
	} );

	$( '#recipeSlider' ).slick( {
		arrows: false,
		dots: true,
		infinite: true,
		speed: 1200,
		slidesToShow: 1,
		autoplay: true,
		autoplaySpeed: 6000,
		pauseOnHover: false,
	} );

	$( document ).on( 'click', '.btn-toggle', function( e ) {
		e.preventDefault();
		const button = $( this );
		const outer = button.siblings( '.review-text' );
		const inner = outer.children( '.review-text-inner' );
		const outerHeight = outer.height();
		const innerHeight = inner.height();

		if ( outer.hasClass( 'expanded') ) {
			outer.removeClass( 'expanded');
			outer.animate( {
				height: '60px',
			}, 1 );
		} else {
			if ( innerHeight > outerHeight ) {
				outer.addClass( 'expanded');
				outer.animate( {
					height: innerHeight,
				}, 1 );
			}
		}
	} );

	/*
	 * Handle cookie policy notification
	*/
	if ( $( '#cookie-policy' ).length > 0 ) {
		$( '#cookie-policy' ).delay( 2000 ).slideDown( 500 , function() {
			setTimeout( function() {
				$( '#cookie-policy' ).slideUp( 500 );
				Cookies.set( 'cookie_policy', 'hidden', { path: '/', expires: 30 } );
			}, 10000);
		} );

		$( '#cookie-policy-dismiss' ).click( function() {
			Cookies.set( 'cookie_policy', 'hidden', { path: '/', expires: 30 } );
			$( '#cookie-policy' ).slideUp( 500 );
		} );
	}

	$( document ).on( 'click', '.question-heading', function( e ) {
        $(this).toggleClass('active');
        $(this).next().slideToggle('fast');
        $('.question-content').not($(this).next()).slideUp('fast');
        $('.question-heading').not($(this)).removeClass('active');
    });

	lightbox.option( {
		disableScrolling: true,
		showImageNumberLabel: false,
	} );

	const mobileOnly = window.matchMedia( '(max-width: 768px)' );

	if ( mobileOnly.matches ) {
		$( document ).on( 'click', '.footer-top .widget-title', function( event ) {
			event.preventDefault();
			$( this ).toggleClass( 'open' );
			$( this ).siblings( '.widget-content' ).slideToggle().toggleClass( '.visible' );
		} );
	}

	// Side Menu toggler
	$( document ).on( 'click', '#toggleMainMenu', function( event ) {
		event.preventDefault();
		const button = $( this );

		if ( button.hasClass( 'open' ) ) {
			$( '#navigationMobile' ).slideToggle();
			button.removeClass( 'open' );
		} else {
			$( '#navigationMobile' ).slideToggle();
			button.addClass( 'open' );
		}
	} );

	$( document ).on( 'click', '#mobile-menu-list > .menu-item-has-children > a > .menu-toggler', function( e ) {
		e.preventDefault();
		const navItem = $( this ).parent().parent();

		if ( navItem.hasClass( 'menu-item-visible' ) ) {
			navItem.children( '.menu-mobile-sub' ).slideToggle();
			navItem.removeClass( 'menu-item-visible' );
		} else {
			navItem.children( '.menu-mobile-sub' ).slideToggle();
			navItem.addClass( 'menu-item-visible' );
		}
	} );

	$( '#qaTable' ).fancyTable( {
		sortable: true,
		sortColumn: 0,
		pagination: true,
		perPage: 20,
		globalSearch: true,
		inputPlaceholder: 'Αναζήτηση',
	} );
} );
